import { GetStaticPropsContext, InferGetStaticPropsType } from "next";

import EditorialEntry from "~/components/editorials/editorial-entry";
import LayoutPage from "~/components/layout/layout-page";
import { usePageTracking } from "~/contexts/page-trackings";
import Constants from "~/utils/constants";
import { isActiveEntry } from "~/utils/editorial-utils";
import ssrUtils from "~/utils/ssr-utils";

type Props = InferGetStaticPropsType<typeof getStaticProps>;

export default function Custom404(props: Props) {
  usePageTracking("notfound");
  return (
    <LayoutPage {...props} navColor="gradient" hidePrefooter>
      <>
        {props.page?.components?.filter(isActiveEntry).map((entry) => (
          <EditorialEntry key={entry.sys.id} entry={entry} />
        ))}
      </>
    </LayoutPage>
  );
}

export async function getStaticProps(context: GetStaticPropsContext) {
  if (context.locale === context.defaultLocale) {
    context.locale = Constants.DEFAULT_FALLBACK_LOCALE;
  }

  const pageData = await ssrUtils.getServerSide404PageData(context);

  return {
    props: {
      ...pageData,
    },
    revalidate: Constants.DEFAULT_STATIC_PAGE_TIME,
  };
}
